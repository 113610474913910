<template>
  <div class="divBox">
    <el-card class="box-card">
      <div class="mb20 acea-row">
        <el-button size="small" type="primary" class="mb10" @click="onAdd"
          >添加商品分类</el-button
        >
        <el-alert
          title="平台商品的分类应添加至三级，否则商户添加商品时无分类可选"
          type="warning"
        />
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="store_category_id"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="分类名称" min-width="200">
          <template slot-scope="scope">
            <span>{{
              scope.row.cate_name + "  [ " + scope.row.store_category_id + "  ]"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分类图标" min-width="80">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.pic ? scope.row.pic : moren"
                :preview-src-list="[scope.row.pic ? scope.row.pic : moren]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" min-width="50" />
        <el-table-column prop="status" label="是否显示" min-width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_show"
              :active-value="1"
              :inactive-value="0"
              active-text="显示"
              inactive-text="隐藏"
              @change="onchangeIsShow(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="是否推荐" min-width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_hot"
              :active-value="1"
              :inactive-value="0"
              active-text="推荐"
              inactive-text="不推荐"
              @change="onchangeIsRecommend(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" min-width="150" />
        <el-table-column label="操作" min-width="60" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onEdit(scope.row.store_category_id)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="handleDelete(scope.row.store_category_id, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="500"
      :before-close="handleClose"
    >
      <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="headPortrait">
          <FormImgUpload
            :url="form.headPortrait"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
// import {
//   storeCategoryListApi, storeCategoryCreateApi, storeCategoryUpdateApi, storeCategoryDeleteApi,
//   storeCategoryStatusApi, storeCategoryRecommendApi
// } from '@/api/product'
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        page: 1,
        limit: 20,
      },
      title: "新增分类",
      dialogVisible: false,
      form: {
        image: "",
        password:'12'
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "上级分类",
            prop: "username",
            type: "cascader",
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            checkStrictly: true,
            props: { checkStrictly: true },
            dicData: [
              {
                value: "zhinan",
                label: "指南",
                children: [
                  {
                    value: "shejiyuanze",
                    label: "设计原则",
                    children: [
                      {
                        value: "yizhi",
                        label: "一致",
                      },
                      {
                        value: "fankui",
                        label: "反馈",
                      },
                    ],
                  },
                ],
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分类名称",
            prop: "password",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分类图片",
            row: true,
            type: "text",
            prop: "headPortrait",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传分类图片",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "是否显示",
            prop: "switch",
            type: "switch",
            row: true,
            dicData: [
              {
                label: "关",
                value: 0,
              },
              {
                label: "开",
                value: 1,
              },
            ],
          },
          {
            label: "管理员角色",
            prop: "roleList",
            type: "select",
            dicData: this.$store.state.system.role,
            row: true,
            multiple: true,
            props: {
              label: "name",
              value: "id",
            },
          },
          {
            label: '排序',
            prop: 'num',
            controlsPosition: '',
            type: 'number'
          }
        ],
      },
    };
  },
  mounted() {
    // this.getList()
  },
  methods: {
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.headPortrait = res.image;
      // this.$nextTick(() => {
      //   this.form.headPortrait = res.path;
      // });
    },
    formSubmit() {},
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;

          if (this.choiceId) {
            userUpdate({
              id: this.choiceId,
              email: form.email,
              fullName: form.fullName,
              headPortrait: form.headPortrait[0],
              username: form.username,
              password: form.password,
              phone: form.phone,
              state: form.state,
              roleList: form.roleList.toString(),
              parentId: form.parentId,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.$root.eventHub.$emit("changeAdd", true);
                  // done();
                } else {
                  that.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          } else {
            userAdd({
              email: form.email,
              fullName: form.fullName,
              headPortrait: form.headPortrait[0],
              username: form.username,
              password: form.password,
              phone: form.phone,
              state: form.state,
              roleList: form.roleList.toString(),
              parentId: form.parentId,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                // console.log(res);
                if (res.code === 200) {
                  that.$message.success(res.msg);
                  this.$root.eventHub.$emit("changeAdd", true);
                  // done();
                } else {
                  that.$message.error(res.msg);
                  // done();
                }
              })
              .catch((err) => {
                that.$message.error(err.msg);
                // done();
              });
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    handleClose() {
      this.dialogVisible=false
    },
    // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    getList() {
      this.listLoading = true;
      storeCategoryListApi(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data;
          this.tableData.total = res.data.count;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    pageChange(page) {
      this.tableData.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableData.limit = val;
      this.getList();
    },
    // 添加
    onAdd() {
      this.dialogVisible = true
    },
    // 编辑
    onEdit(id) {
      this.$modalForm(storeCategoryUpdateApi(id)).then(() => this.getList());
    },
    // 删除
    handleDelete(id, idx) {
      this.$modalSure().then(() => {
        storeCategoryDeleteApi(id)
          .then(({ message }) => {
            this.$message.success(message);
            this.getList();
          })
          .catch(({ message }) => {
            this.$message.error(message);
          });
      });
    },
    onchangeIsShow(row) {
      storeCategoryStatusApi(row.store_category_id, row.is_show)
        .then(({ message }) => {
          this.$message.success(message);
        })
        .catch(({ message }) => {
          this.$message.error(message);
        });
    },
    onchangeIsRecommend(row) {
      storeCategoryRecommendApi(row.store_category_id, row.is_hot)
        .then(({ message }) => {
          this.$message.success(message);
        })
        .catch(({ message }) => {
          this.$message.error(message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
